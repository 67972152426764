import React from "react";
import Layout from "../../layouts";

import './style.css'

const PrivacyPolicyEn = () => {
    return (
        <Layout lang="de" showNav={false}>
            <h1>Klimaticket Tracker – Datenschutzbestimmungen</h1>
            <h2>Verwendete Persönliche Daten</h2>
            <p>
                Sämtliche in der App verwendeten Daten sind nur auf dem Gerät gespeichert. Es findet keine Übertragung
                an irgendwelche Verarbeiter statt.
            </p>

            <h2>User Statistiken</h2>
            <p>
                Genauso wenig werden Statistiken über Nutzungsverhalten und Fehler in der App erhoben.
            </p>

            <h2>Fragen</h2>
            <p>
                Für Fragen stehe ich unter der E-Mail Adresse galler.stefan@gmail.com zur Verfügung.
            </p>
        </Layout>
    )
}

export default PrivacyPolicyEn